<template>
  <div>
    <page-header/>
    <div class="h1">{{$t('title')}}</div>
    <page-footer/>
  </div>
</template>
<script>
  import PageHeader from '../../components/PageHeader';
  import PageFooter from '../../components/PageFooter';

  export default {
    name: 'Page401',
    i18n: {
      messages: {
        'zh-cn': {
          title: '抱歉，你没有权限访问该页面'
        },
        'zh-hk': {
          title: '抱歉，你沒有權限訪問該頁面'
        },
        'en': {
          title: '抱歉，你沒有權限訪問該頁面'
        }
      }
    },
    components: {PageFooter, PageHeader}
  };
</script>
<style scoped lang="less">
  .h1{padding:200px;text-align:center;font-size:22px;}
</style>
